import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import {
  CaseDisplayTranslationResponse,
  CaseGlobalRequest,
  CaseHowToWinTranslation,
  CasePricingTranslation,
  CasesDatabaseList,
  CaseStatusType,
  CaseWinrateResponse,
  ResponsePostContentLangCase,
} from "../../entities/cases";
import {
  ElementOrder,
  HowToWinGlobal,
  JackpotDatabaseContent,
  PopupSettingsForm,
  ResponsePopupLangs,
  ResponsePopupSettings,
} from "../../entities/jackpotGame";
import { addNotification } from "../Notification/notificateStore";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

type CasePrizeListType = {
  casePrizeList: CasesDatabaseList[];
};



export const getCaseDatabases = createAsyncThunk(
  "case/get-database",
  async (): Promise<CasesDatabaseList[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 });
    const req = await agent.get(`${DEFAULT_URL}/list/${query}`);
    if (req.ok) {
      const res = (await req.json()) as CasePrizeListType;
      return res.casePrizeList;
    }
    return [];
  }
);
export const caseStatusChecker = createAsyncThunk(
  'case/status-checker',
  async (props: { caseId: number, target: CaseStatusType }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/status-control/update`, {
      body: JSON.stringify(props),
    })
    if (req.ok) {
      thunksApi.dispatch(getCaseDatabases());
    }
  }
)
export const postCaseGlobal = createAsyncThunk(
  "case/post-global-case",
  async (data: CaseGlobalRequest, thunksApi) => {
    const bonusesObject = data.config.bonusIds.map((id, index) => ({ id, index }))
    const body = {
      ...data,
      config: {
        ...data.config,
        bonusIds: bonusesObject,
      },
    }
    const req = await agent.post(`${DEFAULT_URL}`, {
      body: JSON.stringify(body),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunksApi.dispatch(getCaseDatabases);
      // const elementOrder = [
      //   { position: 0, title: "case_description_first_block" },
      //   { position: 1, title: "case_description_second_block" },
      //   { position: 2, title: "case_description_third_block" },
      //   { position: 3, title: "how_to_win" },
      //   { position: 4, title: "bonuses" },
      // // ];
      // thunksApi.dispatch(postElementOrder({ data: elementOrder, id: res.id }));
      thunksApi.dispatch(getCaseGlobal(res.id))
      thunksApi.dispatch(addNotification({ status: "success", text: "Successfully saved" }));

      thunksApi.dispatch(caseStatusChecker({ caseId: res.id, target: "GlobalSettings" }));
      thunksApi.dispatch(caseStatusChecker({ caseId: res.id, target: "PageElementOrder" }));
      thunksApi.dispatch(caseStatusChecker({ caseId: res.id, target: "PopupSettings" }));

      return res.id as number;
    }
    return undefined;
  }
);

export const patchCaseGlobal = createAsyncThunk(
  "case/patch-global-case",
  async (data: CaseGlobalRequest, thunksApi) => {
    const bonusesObject = data.config.bonusIds.map((id, index) => ({ id, index }))
    const body = {
      ...data,
      config: {
        ...data.config,
        bonusIds: bonusesObject,
      },
    }
    const req = await agent.patch(`${DEFAULT_URL}/${data.id}`, {
      body: JSON.stringify(body),
    });
    if (req.ok) {
      thunksApi.dispatch(getCaseDatabases());
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getCaseGlobal(data.id))
      thunksApi.dispatch(caseStatusChecker({ caseId: data.id, target: "GlobalSettings" }));
    }
  }
);

interface CaseDomainEntity {
  gameEntity: {
    id: number;
    name: string;
    langs: string[];
    config: {
      caseConfigurationNotes: string;
      fakeCaseOpeningStats: "Yes" | "No";
      caseOpeningStats: number;
      primaryTags: string[];
      colorPicker: string;
      bonusIds: { id: string, index: number }[];
      cahortList: string[];
    };
  }
}
export const getCaseGlobal = createAsyncThunk(
  "case/get-global-case",
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}`);
    if (req.ok) {
      const res = (await req.json()) as CaseDomainEntity;
      //TODO Есть у нас есть кейсы с string[], а не по новой архитектуре {id:number, index:number}[]
      if (typeof (res.gameEntity.config.bonusIds[0]) === 'object') {
        const result: CaseGlobalRequest = {
          ...res.gameEntity,
          config: {
            ...res.gameEntity.config,
            bonusIds: res.gameEntity.config.bonusIds.map((item) => `${item.id}`)
          }
        }
        return result;
      } else {
        return res.gameEntity;
      }
    }
    return undefined;
  }
);
// [DELETE]/organization/game/case/{id}
export const deleteCaseById = createAsyncThunk(
  'case/delete-case-by-id',
  async (id: number, thunksApi) => {
    const res = await agent.delete(`${DEFAULT_URL}/${id}`);
    if (res.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successful removal" })
      );
      thunksApi.dispatch(getCaseDatabases());
      return null;
    }
    if (!res.ok) {
      const result = (await res.json()) as { message: string };
      thunksApi.dispatch(
        addNotification({ status: "error", text: "It is not possible to delete the jackpot used in the profile." })
      );
      throw result.message;
    }
    return null;
  }
)

//Content Localization
export const getCaseContent = createAsyncThunk(
  "case/get-content-case",
  async (caseId: number) => {
    const req = await agent.get(
      `${DEFAULT_URL}/${caseId}/content-localization`
    );

    if (req.ok) {
      const res = await req.json();
      return res;
    }
    if (!req.ok) {
      return undefined;
    }
  }
);

export const postCaseContent = createAsyncThunk(
  "case/post-global-content-case",
  async (
    data: {
      caseId: number;
      caseContentLocalizationGlobalSettings: JackpotDatabaseContent;
    },
    thunksApi
  ) => {
    const body = {
      caseContentLocalizationGlobalSettings: {
        ...data.caseContentLocalizationGlobalSettings,
      },
    };
    const req = await agent.post(
      `${DEFAULT_URL}/${data.caseId}/content-localization/create-global-settings`,
      {
        body: JSON.stringify(body),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(getCaseContent(data.caseId));
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "ContentLocalization" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);
export const patchCaseContent = createAsyncThunk(
  "case/patch-global-content-case",
  async (
    data: {
      caseId: number;
      caseContentLocalizationGlobalSettings: JackpotDatabaseContent;
    },
    thunksApi
  ) => {
    const body = {
      caseContentLocalizationGlobalSettings: {
        ...data.caseContentLocalizationGlobalSettings,
      },
    };
    const req = await agent.patch(
      `${DEFAULT_URL}/${data.caseId}/content-localization/update-global-settings`,
      {
        body: JSON.stringify(body),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(getCaseContent(data.caseId));
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "ContentLocalization" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
    if (!req.ok) {
      thunksApi.dispatch(postCaseContent(data))
    }
  }
);

export const postCaseContentTranslation = createAsyncThunk(
  `case/post-content-translates-case`,
  async (data: {
    caseId: number;
    caseGlobalLocalizationLangConfig: ResponsePostContentLangCase;
  }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${data.caseId}/content-localization/add-lang`,
      {
        body: JSON.stringify({
          caseGlobalLocalizationLangConfig: {
            ...data.caseGlobalLocalizationLangConfig,
          },
        }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(getCaseContent(data.caseId));
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "ContentLocalization" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);

export const patchCaseContentTranslation = createAsyncThunk(
  `case/patch-content-translates-case`,
  async (data: {
    caseId: number;
    caseGlobalLocalizationLangConfig: ResponsePostContentLangCase;
  }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${data.caseId}/content-localization/update-lang`,
      {
        body: JSON.stringify({
          caseGlobalLocalizationLangConfig: {
            ...data.caseGlobalLocalizationLangConfig,
          },
        }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(getCaseContent(data.caseId));
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "ContentLocalization" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);

export const postElementOrder = createAsyncThunk(
  `case/post-page-order`,
  async (props: { data: ElementOrder[]; id: number }, thunksApi) => {
    const { data, id } = props;
    const req = await agent.post(`${DEFAULT_URL}/${id}/element-order`, {
      body: JSON.stringify({ config: { items: { ...data } } }),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(caseStatusChecker({ caseId: id, target: "PageElementOrder" }));
      thunksApi.dispatch(getElementOrder(props.id));
    }
  }
);
export const getElementOrder = createAsyncThunk(
  `case/get-page-order`,
  async (id: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${id}/element-order`);
    if (res.ok) {
      const body = (await res.json()) as { config: { items: ElementOrder[] } };
      return Object.values(body.config.items);
    }
  }
);
export const patchElementOrder = createAsyncThunk(
  `case/patch-page-order`,
  async (props: { data: ElementOrder[]; id: number }, thunksApi) => {
    const { data, id } = props;
    const body = {
      config: {
        items: data.map((item, index) => ({
          title: item.title,
          position: index + 1,
        })),
      },
    };
    const req = await agent.patch(`${DEFAULT_URL}/${id}/element-order`, {
      body: JSON.stringify(body),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(caseStatusChecker({ caseId: id, target: "PageElementOrder" }));
      thunksApi.dispatch(getElementOrder(id));
    }
  }
);

export const getPopup = createAsyncThunk(
  `case/get-popup-settings`,
  async (id: number): Promise<ResponsePopupSettings | undefined> => {
    const req = await agent.get(
      `${DEFAULT_URL}/${id}/popup-settings/popup-settings`
    );
    if (req.ok) {
      const res = (await req.json()) as ResponsePopupSettings;
      return res;
    }
    return undefined;
  }
);

export const postPopupSettings = createAsyncThunk(
  `case/post-popup-settings`,
  async (data: { caseId: number; globalSettings: PopupSettingsForm }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${data.caseId}/popup-settings/global-settings`,
      {
        body: JSON.stringify({ globalSettings: data.globalSettings }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "PopupSettings" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPopup(data.caseId))
    }
  }
);

export const patchPopupSettings = createAsyncThunk(
  `case/patch-popup-settings`,
  async (data: { caseId: number; globalSettings: PopupSettingsForm }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${data.caseId}/popup-settings/global-settings`,
      {
        body: JSON.stringify({ globalSettings: data.globalSettings }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "PopupSettings" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPopup(data.caseId))
    }
  }
);

export const postPopupTranslationById = createAsyncThunk(
  "case/post-popup-translation-by-id",
  async (props: { caseId: number; lang: ResponsePopupLangs }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.caseId}/popup-settings/add-lang`, {
      body: JSON.stringify({ lang: props.lang }),
    });
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PopupSettings" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPopup(props.caseId))
    }
  }
);

export const patchPopupTranslationById = createAsyncThunk(
  "case/patch-popup-translation-by-id",
  async (props: { caseId: number; lang: ResponsePopupLangs }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${props.caseId}/popup-settings/update-lang`,
      {
        body: JSON.stringify({ lang: props.lang }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PopupSettings" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPopup(props.caseId))
    }
  }
);

type HowToWinCaseResponse = {
  globalSettings: HowToWinGlobal;
  langs: CaseHowToWinTranslation[];
};

export const getHowToWin = createAsyncThunk(
  "case/get-how-to-win",
  async (id: number): Promise<undefined | HowToWinCaseResponse> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/how-to-win`);
    if (req.ok) {
      const res = await req.json();
      return res;
    }
    return undefined;
  }
);

export const postHowToWinGlobal = createAsyncThunk(
  "case/post-how-to-win-global",
  async (
    data: { globalSettings: HowToWinGlobal; caseId: number },
    thunksApi
  ) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${data.caseId}/how-to-win/create-global-settings`,
      {
        body: JSON.stringify({ globalSettings: data.globalSettings }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "HowToWin" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getHowToWin(data.caseId));
    }
  }
);

export const patchHowToWinGlobal = createAsyncThunk(
  "case/patch-how-to-win-global",
  async (
    data: { globalSettings: HowToWinGlobal; caseId: number },
    thunksApi
  ) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${data.caseId}/how-to-win/update-global-settings`,
      {
        body: JSON.stringify({ globalSettings: data.globalSettings }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "HowToWin" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getHowToWin(data.caseId));
    }
  }
);

export const postHowToWinTranslation = createAsyncThunk(
  "case/post-how-to-win-translation",
  async (data: { caseId: number; lang: any }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${data.caseId}/how-to-win/add-lang`,
      {
        body: JSON.stringify({ lang: data.lang }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "HowToWin" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getHowToWin(data.caseId));
    }
  }
);
export const patchHowToWinTranslation = createAsyncThunk(
  "case/patch-how-to-win-translation",
  async (data: { caseId: number; lang: any }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${data.caseId}/how-to-win/update-lang`,
      {
        body: JSON.stringify({ lang: data.lang }),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: data.caseId, target: "HowToWin" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getHowToWin(data.caseId));
    }
  }
);

export const getWinrate = createAsyncThunk(
  "case/get-win-rate",
  async (caseId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${caseId}/win-rate`);
    if (res.ok) {
      const body = (await res.json()) as { bonuses: CaseWinrateResponse[] };
      return body.bonuses;
    }
  }
);

export const postWinrate = createAsyncThunk(
  "case/post-win-rate",
  async (props: { data: CaseWinrateResponse[]; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const res = await agent.post(`${DEFAULT_URL}/${caseId}/win-rate`, {
      body: JSON.stringify({ bonuses: data }),
    });
    if (res.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "WinRate" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getWinrate(props.caseId));
    }
  }
);

export const patchWinrate = createAsyncThunk(
  "case/patch-win-rate",
  async (props: { data: CaseWinrateResponse[]; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const res = await agent.patch(`${DEFAULT_URL}/${caseId}/win-rate`, {
      body: JSON.stringify({ bonuses: data }),
    });
    if (res.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "WinRate" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getWinrate(props.caseId));
    }
  }
);

export const getDisplay = createAsyncThunk(
  "case/get-display",
  async (caseId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${caseId}/display-bonus`);

    if (res.ok) {
      const body = await res.json();
      return body;
    }
    return undefined;
  }
);

export const postDisplay = createAsyncThunk(
  "case/post-display",
  async (props: { data: { id: number, index: number }[], caseId: number }, thunksApi) => {
    const { data, caseId } = props;
    const res = await agent.post(
      `${DEFAULT_URL}/${caseId}/display-bonus/create-settings`,
      {
        body: JSON.stringify({ bonuses: data }),
      }
    );

    if (res.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getDisplay(caseId));
      thunksApi.dispatch(getCaseGlobal(caseId));
    }
    //TODO если неудачный пост запрос, сделать патч запрос
    if (!res.ok) {
      thunksApi.dispatch(patchDisplay(props));
    }
  }
);

export const patchDisplay = createAsyncThunk(
  "case/patch-display",
  async (props: { data: { id: number, index: number }[], caseId: number }, thunksApi) => {
    const { data, caseId } = props;

    const res = await agent.patch(
      `${DEFAULT_URL}/${caseId}/display-bonus/update-settings`,
      {
        body: JSON.stringify({ bonuses: data }),
      }
    );

    if (res.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getDisplay(caseId));
      thunksApi.dispatch(getCaseGlobal(caseId));
    }
  }
);

export const postDisplayTranslation = createAsyncThunk(
  "case/post-display-translation",
  async (props: { data: CaseDisplayTranslationResponse; caseId: number }, thunksApi) => {
    const { data, caseId } = props;
    const req = await agent.post(`${DEFAULT_URL}/${caseId}/display-bonus/add-lang`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(getDisplay(caseId))
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);

export const patchDisplayTranslation = createAsyncThunk(
  "case/patch-display-translation",
  async (props: { data: CaseDisplayTranslationResponse; caseId: number }, thunksApi) => {
    const { data, caseId } = props;
    const req = await agent.patch(`${DEFAULT_URL}/${caseId}/display-bonus/update-lang`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(getDisplay(caseId))
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);

export const getPricing = createAsyncThunk(
  "case/get-Pricing",
  async (caseId: number) => {
    const res = await agent.get(
      `${DEFAULT_URL}/pricing-and-availability/${caseId}`
    );

    if (res.ok) {
      const body = await res.json();
      return body;
    }
  }
);

export const postPricing = createAsyncThunk(
  "case/post-pricing",
  async (props: { data: any; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const req = await agent.post(
      `${DEFAULT_URL}/pricing-and-availability/${caseId}/global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PricingAndAvailability" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPricing(props.caseId));
    }
  }
);

export const patchPricing = createAsyncThunk(
  "case/patch-pricing",
  async (props: { data: any; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const req = await agent.patch(
      `${DEFAULT_URL}/pricing-and-availability/${caseId}/global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PricingAndAvailability" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPricing(props.caseId));
    }
  }
);

export const postPricingTranslation = createAsyncThunk(
  "case/post-pricing-translation",
  async (props: { data: CasePricingTranslation; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const req = await agent.post(
      `${DEFAULT_URL}/pricing-and-availability/${caseId}/add-lang`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PricingAndAvailability" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPricing(props.caseId));
    }
  }
);
export const patchPricingTranslation = createAsyncThunk(
  "case/patch-pricing-translation",
  async (props: { data: CasePricingTranslation; caseId: number }, thunksApi) => {
    const { caseId, data } = props;
    const req = await agent.patch(
      `${DEFAULT_URL}/pricing-and-availability/${caseId}/update-lang`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(caseStatusChecker({ caseId: props.caseId, target: "PricingAndAvailability" }));
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(getPricing(props.caseId));
    }
  }
);
